import type { ItemModel, DataItemModel} from 'o365.modules.DataObject.Types.ts';
import type DataObject from 'o365.modules.DataObject.ts';
import localStorageHelper from 'o365.modules.StorageHelpers.ts';
import { getLibUrl } from 'o365.modules.helpers.js';
export default class Treeify<T extends ItemModel = ItemModel> {
    private _dataObject: DataObject<T>;
    private _idField: string;
    private _parentField: string;
    private _idPathField?: string;

    private _storage: Record<number, any> = {};
    private _filteredData: any;

    private _originalLoad: any;
    private _indexShifts: any;

    private _loadFromUrlIndex: boolean;
    private urlKey: string;
    private _onUrlKeyNotFound?: Function;

    private enabled = false;
    private _renderingLocked = false;
    private _deepestLevel = 0;
    /**
     * Function that takes in a row and returns a computed id path arrray.
     */
    private formatFunction?: (row: any) => string[];
    get deepestLevel() { return this._deepestLevel; }

    /** Shows last expanded to level */
    currentLevel = 0;

    private get _localKey() {
        return `${this._dataObject.id}_treeify`
    }

    /** Display data array */
    private data: DataItemModel<T>[] = [];


    private loaded = false;

    updated = new Date();
    constructor(pDataObject: DataObject<T>, options: {
        idField?: string,
        parentField?: string,
        idPathField?: string,
        loadFromUrlIndex?: boolean,
        onUrlKeyNotFound?: Function,
        urlKey?: string
    }) {
        if (!options) { options = {}; }
        this._dataObject = pDataObject;
        this._idField = options.idField ?? 'ID';
        this._parentField = options.parentField ?? 'Parent_ID'
        this._idPathField = options.idPathField;
        this._loadFromUrlIndex = options.loadFromUrlIndex ?? true;
        this._onUrlKeyNotFound = options.onUrlKeyNotFound;

        this.urlKey = options.urlKey ?? `${this._dataObject.id}-${this._idField}`

        this._originalLoad = this._dataObject.load;

        const loadWrapper = async (pParams?: Object) => {
            const result = await this._originalLoad.call(this._dataObject, pParams);
            // this._indexShiftCheck();
            return result;
        }
        this._dataObject.load = loadWrapper;
    }

    enable() {
        if (!this.enabled) {
            if (this._dataObject.state.isLoading) {
                const handleLoadedReshift = () => {
                    // this._indexShiftCheck();
                    this._dataObject.off('DataLoaded', handleLoadedReshift);
                };
                this._dataObject.on('DataLoaded', handleLoadedReshift);
            }

            this._dataObject.clientSideFiltering = true;
            this._dataObject.recordSource.maxRecords = -1;
            if (this._idPathField) {
                this._parentField = 'o_parentId';
            }
            this._dataObject.on('CurrentIndexChanged', this.handleIndexChange)
            this._dataObject.dataHandler.groupFormatFunction = this.treeifyFunction.bind(this);

            this._dataObject.setStoragePointer(this.data);
            // if (false && window.location.host === 'omega365-headers.azurewebsites.net') {
            //     this._enableWorker();
            // }
            this.enabled = true;
        }
    }

    disable() {
        if (this.enabled) {
            this._dataObject.clientSideFiltering = false;
            this._dataObject.dataHandler.groupFormatFunction = null;
            this._dataObject.off('CurrentIndexChanged', this.handleIndexChange)
            this._dataObject.setStoragePointer(undefined);
            this._storage = {};
            this._disableWorker();
            this.enabled = false;
        }
    }


    setFormatFunction(formatFunction) {
        this.formatFunction = formatFunction;
        if (formatFunction) {
            this.enable();
        } else {
            this.disable();
        }
    }

    treeifyFunction(pData: Array<any>) {
        const filterApplied = !!this._dataObject.filterObject.appliedFilterString;
        this._deepestLevel = 0;

        if (this.loaded) {
            // Clean the details for existing items since sort or filter might've changed
            Object.keys(this._storage).forEach(key => {
                this._storage[key].details = [];
            });
        }

        if (this._worker) {
            return this._execute('treeify', {
                data: pData,
                filterApplied: filterApplied,
                idField: this._idField,
                parentField: this._parentField,
                idPathField: this._idPathField,
                filteredData: this._filteredData ?? {},
                storedStates: this._getStoredStates(),
                storage: this._storage,
            }).then((response) => {
                this._deepestLevel = response.deepestLevel;
                this._filteredData = response.filteredData;
                this._storage = response.storage;
                return response.returnData;
            }).catch((ex) => {
                console.error(ex);
                return [];
            }).finally(() => {
                this._update();

                if (!this.loaded) {
                    this.loadIndexFromURL();
                    this.loaded = true;
                }
            });
        }

        const topLevel: any[] = [];
        let returnData: any[] = [];

        this.data.splice(0, this.data.length);

        const assignToStorage = (item) => {
            const itemScope = this._storage[item[this._idField]];
            if (!itemScope) { this._storage[item[this._idField]] = { item: null, details: [] } };

            if (!this._storage[item[this._idField]].item) {
                this._storage[item[this._idField]].item = item;
                delete nullItems[item[this._idField]];
            }
        };

        let newItemAdded = false;
        const nullItems = {};
        for (let i = 0; i < pData.length; i++) {
            let vRow = pData[i];

            if (vRow.hasOwnProperty(this._parentField)) {
                // if (vRow.hasOwnProperty('o_level')) {
                const id = this.getId(vRow);
                if (this._storage[id]) {

                    // Item was previously formated. Re-add it to parent details

                    if (vRow.o_level === 0) {
                        const itemId = this.getId(vRow);
                        topLevel.push(this._storage[this.getId(vRow)]);
                    }
                    if (vRow.o_level > this._deepestLevel) { this._deepestLevel = vRow.o_level; }
                    if (vRow.key) { console.log(vRow); }
                    this._storage[id].item = vRow; // Avoid having DataItem in _storage when reruning treeify 


                    if (vRow.o_parentId != null) {
                        this._storage[vRow.o_parentId].details.push(id);
                    }
                    continue;
                }
            }
            newItemAdded = true;

            if (this._idPathField) {
                const idPath = vRow[this._idPathField]?.split('/').filter(x => !!x);
                if (idPath?.length > 1) {
                    vRow.o_parentId = idPath[idPath.length - 2];
                }
            }

            assignToStorage(vRow);

            const parentId = vRow[this._parentField];

            if (parentId != null) {
                if (!this._storage[parentId]) {
                    this._storage[parentId] = { item: null, details: [] };
                    nullItems[parentId] = true;
                    // this._storage[parentId].item[this._idField] = parentId;
                }
                this._storage[parentId].details.push(vRow[this._idField]);
            } else {
                topLevel.push(this._storage[vRow[this._idField]]);
            }
        }

        if (newItemAdded) {
            Object.keys(nullItems).forEach(key => {
                this._storage[key].item = {};
                this._storage[key].item[this._idField] = key;
            });
            const setupDetails = (row, level = 0, detailsCount = 0) => {
                const id = row.item[this._idField];
                const itemHasDetails = this._storage[id].details.length > 0;

                row.item.o_level = level;
                if (level > this._deepestLevel) { this._deepestLevel = level; }
                if (itemHasDetails) {
                    row.item.o_hasDetails = true;
                    row.details.forEach(detailId => {
                        const detail = this._storage[detailId];
                        detailsCount += setupDetails(detail, level + 1);
                    });
                }
                row.item.o_detailsCount = detailsCount;

                detailsCount++;
                return detailsCount;
            };

            // Recalculate topLevel
            topLevel.splice(0, topLevel.length);
            const checkedIds = new Set<any>();

            const recursiveTopCheck = (id) => {
                id = `${id}`;
                if (checkedIds.has(id)) { return; }
                checkedIds.add(id);
                const row = this._storage[id].item;
                const parentId = row[this._parentField];
                if (parentId == null) {
                    topLevel.push(this._storage[id]);
                } else {
                    recursiveTopCheck(parentId);
                }
            };
            pData.forEach(row => {
                const itemId = this.getId(row);
                recursiveTopCheck(itemId);
            });

            topLevel.forEach(row => {
                setupDetails(row);
            });
        }

        //if (!this.loaded) {
        // Restore expanded states from localstore
        const storedStates = this._getStoredStates();
        Object.entries(storedStates).forEach(([id, expanded]) => {
            if (this._storage[id]?.item) {
                this._storage[id].item.o_expanded = expanded;
            }
        });
        //}

        this._filteredData = {};
        pData.forEach(item => {
            const id = this.getId(item);
            this._filteredData[id] = item;
        });

        if (filterApplied) {
            const filteredTop: any[] = [];
            const root = {};

            const recursivePush = (item) => {
                const id = this.getId(item);
                if (root[id]?.item) { return; }
                const parentId = this.getParentId(item);

                if (!this._filteredData[id]) {
                    this._filteredData[id] = item;
                }

                if (parentId) {
                    if (!root[id]) {
                        root[id] = { item: item, details: [] };
                    } else {
                        root[id].item = item;
                    }

                    if (this._storage[parentId].item._item != null) {
                        // Item in storage is DataItem, should be value object instead
                        this._storage[parentId].item = this._storage[parentId].item._item;
                    }
                    const parentItem = this._storage[parentId].item;
                    if (!root[parentId]) {
                        root[parentId] = { item: null, details: [] };
                        if (parentItem.o_level === 0) { filteredTop.push(parentItem); }
                    }
                    root[parentId].details.push(item);
                    recursivePush(parentItem);
                } else {
                    if (!root[id]) {
                        root[id] = { item: item, details: [] };
                        filteredTop.push(item);
                    } else {
                        root[id].item = item;
                    }
                }
            };

            pData.forEach(item => {
                recursivePush(item);
            });

            // Update details to storage
            Object.keys(root).forEach((key) => {
                this._storage[key].details = [...root[key].details.map(x => this.getId(x))];
            });

            // Previous filtered implementation that would expand down to results
            // const pushToReturn = (item) => {
            //     const id = this.getId(item);
            //     returnData.push(item);
            //     if (root[id].details.length > 0) {
            //         item.o_expanded = true;
            //         root[id].details.forEach(detail => pushToReturn(detail));
            //     } else {
            //         item.o_expanded = false;
            //     }
            // };

            const pushToReturn = (item) => {
                returnData.push(item);
                if (item.o_expanded) {
                    const id = this.getId(item);
                    root[id].details.forEach(detail => pushToReturn(detail));
                }
            };
            filteredTop.forEach(item => pushToReturn(item));
        } else {

            const pushToReturn = (row) => {
                returnData.push(row.item);
                if (row.item.o_expanded) {
                    row.details.forEach(detailId => {
                        pushToReturn(this._storage[detailId]);
                    });
                }
            };
            topLevel.forEach(row => pushToReturn(row));
            //returnData = topLevel.map(row => row.item);
        }

        this._update();

        if (!this.loaded) {
            this.loadIndexFromURL();
            this.loaded = true;
        }

        returnData.forEach((item, index) => {
            if (item.index !== index) {
                if (!this._indexShifts) { this._indexShifts = {}; }
                this._indexShifts[index] = item.index;
                item.index = index;
            }
            // const item = this._checkForDataItem(item);
            this.data.push(this._dataObject.storage.addItem(item, index));
        });


        return this.data;
    }

    expand(item, index) {
        if (!item) {
            item = this._dataObject.current;
        }

        if (!item.o_hasDetails || item.o_expanded) { return; }

        if (index == null) {
            index = this._dataObject.data.findIndex(x => this.getId(x) === this.getId(item));
        }

        item = this._checkForDataItem(item);

        const pushDetail = (detailId => {
            if (!this._filteredData[detailId]) { return; }
            const detail = this._storage[detailId];
            if (detail.item.key === undefined) {
                detail.item = this._dataObject.storage.addItem(detail.item, this._dataObject.storage.data.length);
            }
            dataToReturn.push(detail.item);
            if (detail.item.o_expanded) {
                detail.details.forEach(subDetailId => pushDetail(subDetailId));
            }
        });

        const dataToReturn: any[] = [];

        const itemId = this.getId(item);
        this._storage[itemId].details.forEach(detailId => pushDetail(detailId));
        item.o_expanded = true;
        this._storeItemState(item, true);
        // dataToReturn.forEach(dataItem => {

        // })

        //const dataItems = this._dataObject.storage.setItems(dataToReturn);
        //this._dataObject.data.splice(this._dataObject.data.length-dataItems.length, this._dataObject.data.length);

        // this._dataObject['_data'].splice(index + 1, 0, ...dataToReturn);
        // this._dataObject.storage.data.splice(index + 1, 0, ...dataToReturn);
        this.data.splice(index + 1, 0, ...dataToReturn);
        this._indexFix(index + 1);
        this._update();
    }

    expandToItemByPrimKey(primKey: string) {
        if (primKey == null) { return; }
        let itemId: number | null = null;
        const foundId = Object.entries(this._storage).some(([key, value]) => {
            if (value.item?.PrimKey === primKey) {
                itemId = parseInt(key);
                return true;
            } else {
                return false;
            }
        });
        if (foundId && itemId != null) {
            this.expandToItemById(itemId);
        }
    }

    expandToItemById(id: number) {
        if (id == null) { return; }
        const detail = this._storage[id];
        if (detail?.item) {
            this.expandToItem(detail.item);
        }
    }

    expandToItem(item) {
        this._renderingLocked = true;
        if (typeof item === 'number') {
            item = this._dataObject.storage.getItem(item);
        } else if (!item) {
            item = this._dataObject.current;
        }
        const pathStack: any[] = [];
        let parentItem = this._storage[item[this._parentField]];
        while (parentItem) {
            pathStack.push(parentItem.item[this._idField]);
            parentItem = this._storage[parentItem.item[this._parentField]];
        }

        while (pathStack.length > 0) {
            const itemId = pathStack.pop();
            this.expand(this._storage[itemId].item, null);
        }
        this._renderingLocked = false;
        this._update();
    }

    /**
     * Expands all direct children rows of the given item
     * @param item DataItem for which to expand all children
     */
    expandChildren(item) {
        this._renderingLocked = true;
        if (typeof item === 'number') {
            item = this._dataObject.storage.getItem(item);
        } else {
            item = this._dataObject.current;
        }

        const key = this.getId(item);
        if (!this._storage[key]) { return; }
        this._storage[key].details.forEach(detailKey => {
            const detailItem = this._storage[detailKey].item;
            // TODO: Compute the expected index and pass it
            this.expand(detailItem, null);
        });

        this._renderingLocked = false;
        this._update();
    }

    /**
     * Expands all rows to the given level.
     * @param level the level to expand up to
     */
    expandToLevel(level: number) {
        this._renderingLocked = true;
        for (let i = 0; i < this._dataObject.data.length; i++) {
            const item = this._dataObject.data[i];
            if (item.o_level != null) {
                if (item.o_level < level) {
                    this.expand(item, i);
                } else {
                    this.collapse(item, i);
                }
            }
        }
        this._renderingLocked = false;
        this.currentLevel = level;
        this._update();
    }

    collapse(item, index) {
        if (!item) {
            item = this._dataObject.current;
        }

        if (!item.o_hasDetails) { return; }

        if (index == null) {
            index = this._dataObject.data.findIndex(x => this.getId(x) === this.getId(item));
        }

        const currentLevel = item.o_level;
        let lastIndex;
        for (let i = index + 1; i < this._dataObject.data.length; i++) {
            const row = this._dataObject.data[i];
            if (row['o_level'] <= currentLevel) {
                lastIndex = i - 1;
                break;
            }
        }

        if (lastIndex == null) { lastIndex = this._dataObject.data.length - 1; }

        item['o_expanded'] = false;
        this._storeItemState(item, false);
        // this._dataObject['_data'].splice(index + 1, lastIndex - index);
        // this._dataObject.storage.data.splice(index + 1, lastIndex - index);
        this.data.splice(index + 1, lastIndex - index);
        this._indexFix(index + 1);
        this._update();
    }

    /**
     * Collapses all direct children of an item
     * @param item DataItem for which to collapse all children
     */
    collapseChildren(item) {
        this._renderingLocked = true;
        if (typeof item === 'number') {
            item = this._dataObject.storage.getItem(item);
        } else {
            item = this._dataObject.current;
        }

        const key = this.getId(item);
        if (!this._storage[key]) { return; }
        this._storage[key].details.forEach(detailKey => {
            const detailItem = this._storage[detailKey].item;
            // TODO: Compute the expected index and pass it
            this.collapse(detailItem, null);
        });
        this._renderingLocked = false;
        this._update();
    }

    private getId(item) {
        return item[this._idField];
    }

    private getParentId(item) {
        return item[this._parentField];
    }

    private handleIndexChange = (prevIndex, newIndex) => {
        if (!this._loadFromUrlIndex) { return; }
        if (prevIndex === newIndex || !this.loaded) { return; }

        const currentUrl = new URL(window.location.href);
        const searchParams = new URLSearchParams(currentUrl.search);
        const item = this._dataObject.storage.getItem(newIndex);
        searchParams.set(this.urlKey, item[this._idField]);
        currentUrl.search = searchParams.toString();
        history.pushState({}, '', currentUrl);
    }

    private loadIndexFromURL() {
        if (!this._loadFromUrlIndex) { return; }
        const searchParams = new URLSearchParams(window.location.search);
        const itemId = parseInt(searchParams.get(this.urlKey) ?? '');
        if (!itemId) {
            this._loadedIndex = null;
            this._indexGetterResolves?.forEach(res => res(null));
            return;
        }
        window.setTimeout(() => {
            const item = this._storage[itemId];
            if (!item) {
                if (this._onUrlKeyNotFound) {
                    this._onUrlKeyNotFound(itemId);
                }
                return;
            }
            this.expandToItem(item.item);
            this._dataObject.setCurrentIndex(item.item.index);
            this._indexGetterResolves?.forEach(res => res(item.item.index));
        }, 10);
    }

    private _indexGetterResolves: Array<(value: unknown) => any>;
    private _loadedIndex;
    getLoadedIndexFromURL() {
        if (this._loadedIndex === null) { return new Promise(res => res(null)); }

        if (this._loadedIndex) {
            return new Promise(res => res(this._loadedIndex));
        }

        if (!this._indexGetterResolves) { this._indexGetterResolves = []; }
        return new Promise(res => {
            this._indexGetterResolves.push(res);
        });
    }

    private _getStoredStates(): { [key: string]: boolean } {
        try {
            const jsonString = localStorageHelper.getItem(this._localKey);
            if (!jsonString) { return {}; }
            const storedStates = JSON.parse(jsonString);
            return storedStates ?? {};
        } catch (ex) {
            console.error(ex);
            return {};
        }
    }

    private _storeItemState(item: any, expanded: boolean) {
        if (!item) { return; }
        const storedStates = this._getStoredStates();
        const itemId = this.getId(item);
        if (expanded) {
            storedStates[itemId] = true;
        } else {
            delete storedStates[itemId];
        }
        if (Object.keys(storedStates).length === 0) {
            localStorageHelper.removeItem(this._localKey);
        } else {
            localStorageHelper.setItem(this._localKey, JSON.stringify(storedStates));
        }
    }

    /**
     * Checks for index shifts and applies them
     */
    // private _indexShiftCheck() {
    //     if (this._indexShifts) {
    //         Object.keys(this._indexShifts).forEach(dataIndex => {
    //             const index = parseInt(dataIndex);
    //             this._dataObject.data[index]['index'] = this._indexShifts[index];
    //         });
    //         delete this._indexShifts;
    //     }
    // }

    private _update() {
        if (!this._renderingLocked) {
            window.requestAnimationFrame(() => {
                this.updated = new Date();
            });
        }
    }

    private _indexFix(start: number) {
        return;
        for (let i = start; i < this._dataObject.data.length; i++) {
            this._dataObject.data[i].index = i;
        }
    }

    private _checkForDataItem(item: any) {
        if (item == null) { return; }

        if (item.key == null) {
            const id = this.getId(item);
            if (this._storage[id] == null) { return item; }
            const storageIndex = this._dataObject.storage.itemsIdMap.get(id);
            if (storageIndex != null) {
                this._storage[id].item = this._dataObject.storage.items[storageIndex];
                return this._storage[id].item;
            }
        }
        return item;
    }

    //--- Worker ---
    private _worker: Worker | null = null;
    private _callbacks: Record<string, Function> = {};

    // private _enableWorker() {
    //     if (this._worker == null) {
    //         this._worker = new Worker(getLibUrl('o365.modules.Worker.Treeify.ts'));
    //         this._worker.onmessage = this._onMessage.bind(this);
    //     }
    // }

    private _disableWorker() {
        if (this._worker != null) {
            this._worker.terminate();
            this._worker = null;
        }
    }

    private async _execute<T extends ITreeifyMessage['operation']>(operation: T, payload: Record<string, any>) {
        const timeout = 10000
        if (this._worker != null) {
            const uid = crypto.randomUUID();
            const messageObject = {
                operation: operation,
                payload: payload,
                meta: {
                    uid: uid,
                }
            } as ITreeifyMessage;
            let promiseRes: Function;
            let promiseRej: Function;
            const promise = new Promise<any>((res, rej) => {
                promiseRes = res;
                promiseRej = rej;
            });
            const timeoutDebounce = setTimeout(() => {
                delete this._callbacks[uid];
                promiseRej(new Error('Treeify worker timeout'));
            }, timeout);
            this._callbacks[uid] = (success: boolean, result?: any) => {
                clearTimeout(timeoutDebounce);
                delete this._callbacks[uid];
                if (!success) {
                    promiseRej(new Error(result ?? 'Recieved failed status'));
                } else {
                    promiseRes(result);
                }
            };
            this._worker.postMessage(JSON.stringify(messageObject));
            return promise;
        }
    }

    private _onMessage(e: MessageEvent) {
        const message = JSON.parse(e.data);

        if (message.operation === 'callback') {
            const uid = message.meta.uid;
            const callback = this._callbacks[uid];
            if (typeof callback === 'function') {
                callback(message.success, message.payload);
            }
        } else {
            try {
                switch (message.operation) {
                    case 'getStoredStates':
                        const result = this._getStoredStates();
                        const response = {
                            operation: 'callback',
                            payload: result,
                            success: true,
                            meta: { uid: message.meta.uid }
                        } as ITreeifyMessage;
                        postMessage(JSON.stringify(response));
                        break;
                }
            } catch (ex) {
                const response = {
                    operation: 'callback',
                    payload: ex?.message ?? 'Failed to execute',
                    success: false,
                    meta: { uid: message.meta.uid }
                } as ITreeifyMessage;
                postMessage(JSON.stringify(response));
            }
        }
    }
}